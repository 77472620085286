import React, { Fragment, useEffect } from "react"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"

import SEO from "~components/seo"

import EmailAddress from "./components/_emailAddress"
import PhoneNumber from "./components/_phoneNumber"
import Instagram from "./components/_instagram"

import "./info.css"

export default ({ data: { allSanityWebsiteConfiguration } }) => {
  const info = allSanityWebsiteConfiguration.edges[0].node
  useEffect(() => {
    document.documentElement.style.setProperty("--color-background", "#E5D5D8")
    document.documentElement.style.setProperty("--color-foreground", "#64657A")
  }, [])

  return (
    <Fragment>
      <SEO title="Info" />
      <div className="info">
        <div className="biography">
          <BlockContent blocks={info._rawBiography} />
        </div>
        <div className="contact">
          <div>
            <p>Contact</p>
            <br />
            <EmailAddress emailAddress={info.emailAddress} />
            <PhoneNumber phoneNumber={info.phoneNumber} />
            <Instagram username={info.instagram} />
          </div>
          {info._rawFootnote ? (
            <div className="footnote">
              <BlockContent blocks={info._rawFootnote} />
            </div>
          ) : null}
        </div>
      </div>
    </Fragment>
  )
}

export const query = graphql`
  query InfoPageQuery {
    allSanityWebsiteConfiguration {
      edges {
        node {
          _rawBiography
          _rawFootnote
          emailAddress
          phoneNumber
          instagram
          twitter
          facebook
        }
      }
    }
  }
`
