import React from "react"

export default ({ emailAddress }) => {
  if (!emailAddress) return null
  return (
    <p>
      <a href={`mailto:${emailAddress}`} target="_blank" rel="noreferrer">
        {emailAddress}
      </a>
    </p>
  )
}
