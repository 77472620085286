import React from "react"

export default ({ username }) => {
  if (!username) return null
  return (
    <p>
      <a
        href={`https://www.instagram.com/${username}`}
        target="_blank"
        rel="noreferrer"
      >
        @{username}
      </a>
    </p>
  )
}
