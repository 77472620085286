import React from "react"

export default ({ phoneNumber }) => {
  if (!phoneNumber) return null
  return (
    <p>
      <a href="/" alt="" target="_blank" rel="noreferrer">
        {phoneNumber}
      </a>
    </p>
  )
}
